
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_EMPLOYERS,
    GET_EMPLOYERS_LOADING,
    GET_EMPLOYERS_SUCCESS,
    GET_EMPLOYERS_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';

const baseURL = getBaseURL()

function* getEmployers({ body }) {
    yield put({ type: GET_EMPLOYERS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/employers',
            body
        });
        yield put({ type: GET_EMPLOYERS_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYERS_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployerSaga() {
    yield takeLatest(GET_EMPLOYERS, getEmployers);
}