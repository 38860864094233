
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_EMPLOYERS_LIST,
    GET_EMPLOYERS_LIST_LOADING,
    GET_EMPLOYERS_LIST_SUCCESS,
    GET_EMPLOYERS_LIST_ERROR,

    GET_PRESIGNED_URL,
    GET_PRESIGNED_URL_LOADING,
    GET_PRESIGNED_URL_SUCCESS,
    GET_PRESIGNED_URL_ERROR,

    GET_EMPLOYER_CONFIGURATION,
    GET_EMPLOYER_CONFIGURATION_LOADING,
    GET_EMPLOYER_CONFIGURATION_SUCCESS,
    GET_EMPLOYER_CONFIGURATION_ERROR,
} from './actions';
import { api } from '../utils/api';
import { errorhandling, getBaseURL } from '../utils/helper';

const baseURL = getBaseURL()

function* getEmployersList({ body }) {
    yield put({ type: GET_EMPLOYERS_LIST_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/employers/lookup',
            body
        });
        yield put({ type: GET_EMPLOYERS_LIST_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYERS_LIST_ERROR, errorMessage: '' });
    }
}

function* getPresignedUrl({ body }) {
    yield put({ type: GET_PRESIGNED_URL_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'uploads/getsignedurl',
            body
        });
        yield put({ type: GET_PRESIGNED_URL_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_PRESIGNED_URL_ERROR, errorMessage: '' });
    }
}

function* getEmployerConfiguration({ body }) {
    yield put({ type: GET_EMPLOYER_CONFIGURATION_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/employerConfiguration/{employerId}',
            body
        });
        yield put({ type: GET_EMPLOYER_CONFIGURATION_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_CONFIGURATION_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchSharedSaga() {
    yield takeLatest(GET_EMPLOYERS_LIST, getEmployersList)
    yield takeLatest(GET_PRESIGNED_URL, getPresignedUrl)
    yield takeLatest(GET_EMPLOYER_CONFIGURATION, getEmployerConfiguration)

}