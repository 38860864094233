import { call, put, takeLatest } from 'redux-saga/effects';
import {
    LOGIN,
    LOGIN_LOADING,
    LOGIN_SUCCESS,
    LOGIN_ERROR,

    INVITE,
    INVITE_LOADING,
    INVITE_SUCCESS,
    INVITE_ERROR,

    UPDATE_USER_ATTRIBUTES,
    UPDATE_USER_ATTRIBUTES_LOADING,
    UPDATE_USER_ATTRIBUTES_SUCCESS,
    UPDATE_USER_ATTRIBUTES_ERROR,

    SAVE_USER_DETAILS,

    LOGOUT
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';
import { SET_SELECTED_EMPLOYER } from '../../shared/actions';
import { message } from 'antd';

const baseURL = getBaseURL()

function* login({ body }) {
    yield put({ type: LOGIN_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'auth/signin',
            body
        });
        if (["EMPLOYER", "appAdmin", "SUPPORT"].indexOf(data.userDetails["custom:UserType"]) === -1) {
            yield put({ type: LOGIN_ERROR, errorMessage: '' });
            message.error("You don't have access to admin portal!")
        } else {
            if (data.userDetails["custom:UserType"] === "EMPLOYER") {
                yield put({ type: SET_SELECTED_EMPLOYER, data: { companyName: data.userDetails["custom:FirstName"], id: data.userDetails["custom:EmployerId"] } });
            }
            yield put({ type: SAVE_USER_DETAILS, data: data.userDetails });
            yield put({ type: LOGIN_SUCCESS, data: data.AuthenticationResult });
        }
    } catch (error) {
        errorhandling(error)
        yield put({ type: LOGIN_ERROR, errorMessage: '' });
    }
}

function* invite({ body }) {
    yield put({ type: INVITE_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'auth/signin',
            body
        });
        yield put({ type: INVITE_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: INVITE_ERROR, errorMessage: '' });
    }
}

function* updateUserAttributes({ body }) {
    yield put({ type: UPDATE_USER_ATTRIBUTES_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'auth/adminUpdateUserAttributes',
            body: JSON.stringify(body)
        });
        yield put({ type: UPDATE_USER_ATTRIBUTES_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPDATE_USER_ATTRIBUTES_ERROR, errorMessage: '' });
    }
}

function* logout({ body }) {
    try {
        yield call(api, {
            method: 'POST',
            url: baseURL + 'auth/signout',
            body
        });
    } catch (error) {
        errorhandling(error);
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchLoginSaga() {
    yield takeLatest(LOGIN, login)
    yield takeLatest(INVITE, invite)
    yield takeLatest(LOGOUT, logout)
    yield takeLatest(UPDATE_USER_ATTRIBUTES, updateUserAttributes)
}