import React from 'react';
import { Radio } from 'antd';

const CustomRadio = ({ value, options, onChange, radioStyle, disabled }) => {
    return (
        <Radio.Group onChange={(e) => onChange(e.target.value)} value={value} disabled={disabled || false}>
            { options.map((item, index) => <Radio style={radioStyle} key={index} value={item.value}>{item.label}</Radio>)}
        </Radio.Group>
    );
}

export default CustomRadio