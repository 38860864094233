import React from 'react'
import { Layout, Row, Col, Dropdown, Menu, Typography } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    UserOutlined,
    LogoutOutlined
} from '@ant-design/icons';
import '../../style.css'
import { CustomDropdown } from '../..';

const { Header } = Layout;
const { Text } = Typography

const LoggedInUserInfo = ({ handleMenuClick, userDetails, handleLogOut }) => {
    return (
        <Menu onClick={handleMenuClick}>
            <Menu.Item key="">
                <Row>
                    <Col span={12}>
                        <Text>{userDetails["custom:FirstName"]}</Text>
                    </Col>
                </Row>
            </Menu.Item>
            <Menu.Item key="change-password">
                Change Password
            </Menu.Item>
            <Menu.Item onClick={handleLogOut} key="logout" icon={<LogoutOutlined />}>
                Logout
        </Menu.Item>
        </Menu>
    );
}


const CustomHeader = ({ isSidebarCollapsed, toggleSidebar, employers, selectedEmployer, setSelectedEmployer,
    isEmployerDropdownVisible, handleMenuClick, userDetails, handleLogOut, path }) => {
    return (
        <Header className="container">
            <Row className="trigger">
                <Col span={1} onClick={() => toggleSidebar(!isSidebarCollapsed)}>
                    {isSidebarCollapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Col>
                <Col span={isEmployerDropdownVisible ? 17 : 21}></Col>
                {isEmployerDropdownVisible ?
                    <Col span={4}>
                        <CustomDropdown
                            value={selectedEmployer.value}
                            disabled={['/', '/superadmin-dashboard'].indexOf(path) > -1}
                            onChange={(value) => value ? setSelectedEmployer(employers.find(item => item.id === value)) : setSelectedEmployer({ companyName: "All Employers", id: "" })}
                            options={[{ companyName: "All Employers", id: "" }].concat(employers).map(item => ({ label: item.companyName, value: item.id }))}
                            placeholder={"Choose employer"}
                        />
                    </Col>
                    : null}
                <Col span={2} style={{ textAlign: "center" }}>
                    <Dropdown overlay={
                        <LoggedInUserInfo
                            handleMenuClick={handleMenuClick}
                            userDetails={userDetails}
                            handleLogOut={handleLogOut}
                        />} trigger={['click']}>
                        <UserOutlined />
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    )
}

export default CustomHeader