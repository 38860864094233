export const env = "dev"

export const dev = {
    baseURL: 'https://services.egaliti.com/',
    s3BucketPath: "https://salaryadvance-egaliti-uploads.s3.ap-southeast-2.amazonaws.com",
    s3PayrollBucket: "filebasepayrollservice-sa-bucket-dev"
}

export const stage = {
    baseURL: 'https://services.stage.paytime.com.au/',
    s3BucketPath: "https:/salaryadvance-stage-uploads.s3.ap-southeast-2.amazonaws.com",
    s3PayrollBucket: "filebasepayrollservice-bucket-stage"
}

export const qat = {
    baseURL: 'https://services.qat.paytime.com.au/',
    s3BucketPath: "https://salaryadvance-qat-uploads.s3.ap-southeast-2.amazonaws.com",
    s3PayrollBucket: "filebasepayrollservice-bucket-qat"
}

export const prod = {
    baseURL: 'https://services.prod.paytime.com.au/',
    s3BucketPath: "https://salaryadvance-prod-uploads.s3.ap-southeast-2.amazonaws.com",
    s3PayrollBucket: "filebasepayrollservice-bucket-prod"
}
