
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_PAY_CYCLES,
    GET_PAY_CYCLES_LOADING,
    GET_PAY_CYCLES_SUCCESS,
    GET_PAY_CYCLES_ERROR,

    CHANGE_PAYCYCLE_STATUS,
    CHANGE_PAYCYCLE_STATUS_LOADING,
    CHANGE_PAYCYCLE_STATUS_SUCCESS,
    CHANGE_PAYCYCLE_STATUS_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';

const baseURL = getBaseURL()

function* getPayCycles({ body }) {
    yield put({ type: GET_PAY_CYCLES_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/employer/recentPayPeriods/{employerId}',
            body
        });
        yield put({ type: GET_PAY_CYCLES_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_PAY_CYCLES_ERROR, errorMessage: '' });
    }
}


function* changePayCyclesStatus({ body }) {
    const { employerId } = body
    yield put({ type: CHANGE_PAYCYCLE_STATUS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'PUT',
            url: baseURL + 'salaryadvance/salary-manager/employer/employerPayPeriods/status',
            body
        });
        yield put({ type: CHANGE_PAYCYCLE_STATUS_SUCCESS, data: data });
        yield put({ type: GET_PAY_CYCLES, body: { employerId } });
    } catch (error) {
        errorhandling(error)
        yield put({ type: CHANGE_PAYCYCLE_STATUS_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchPromotionsSaga() {
    yield takeLatest(GET_PAY_CYCLES, getPayCycles)
    yield takeLatest(CHANGE_PAYCYCLE_STATUS, changePayCyclesStatus)
}