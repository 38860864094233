import React from 'react'
import { RiDashboardFill } from 'react-icons/ri'
import { FaUserTie, FaBuilding, FaFileInvoiceDollar, FaTicketAlt, FaWallet, FaRecycle, FaUsers, 
    FaFileInvoice, FaDatabase } from 'react-icons/fa';
import { BiTransfer } from 'react-icons/bi';
import { MdPerson } from 'react-icons/md';
import { BsFillPersonCheckFill } from 'react-icons/bs';
import Icon from '@ant-design/icons';

export const employerAdminMenuConfig = [
    {
        path: "/",
        icon: <Icon component={RiDashboardFill} style={{ fontSize: 20 }} />,
        label: "Employer Dashboard"
    },
    {
        path: "/employer-view",
        icon: <Icon component={MdPerson} style={{ fontSize: 20 }} />,
        label: "Employer"
    },
    {
        path: "/employee-eligiblity",
        icon: <Icon component={BsFillPersonCheckFill} style={{ fontSize: 20 }} />,
        label: "Employees Eligiblity"
    },
    {
        path: "/employees",
        icon: <Icon component={FaUserTie} style={{ fontSize: 20 }}/>,
        label: "Employees"
    },
    {
        path: "/transactions",
        icon: <Icon component={BiTransfer} style={{ fontSize: 20 }} />,
        label: "Transactions"
    },
    {
        path: "/pay-cycles",
        icon: <Icon component={FaRecycle} style={{ fontSize: 20 }} />,
        label: "Pay Cycles"
    },
    {
        path: "/pay-period",
        icon: <Icon component={FaWallet} style={{ fontSize: 20 }} />,
        label: "Start Pay Period"
    },
    {
        path: "/users",
        icon: <Icon component={FaUsers} style={{ fontSize: 20 }} />,
        label: "Users"
    },
    {
        path: "/deduction-summary",
        icon: <Icon component={FaFileInvoice} style={{ fontSize: 20 }} />,
        label: "Deduction Summary"
    },
    {
        path: "/payroll-data",
        icon: <Icon component={FaDatabase} style={{ fontSize: 20 }} />,
        label: "Payroll Data"
    }
]

export const superAdminMenuConfig = [
    {
        path: "/",
        icon: <Icon component={FaBuilding} style={{ fontSize: 20 }}/>,
        label: "Employers"
    },
    {
        path: "/employer-dashboard",
        icon: <Icon component={RiDashboardFill} style={{ fontSize: 20 }} />,
        label: "Employer Dashboard"
    },
    {
        path: "/employee-eligiblity",
        icon: <Icon component={BsFillPersonCheckFill} style={{ fontSize: 20 }} />,
        label: "Employees Eligiblity"
    },
    {
        path: "/employees",
        icon: <Icon component={FaUserTie} style={{ fontSize: 20 }}/>,
        label: "Employees"
    },
    {
        path: "/transactions",
        icon: <Icon component={BiTransfer} style={{ fontSize: 20 }} />,
        label: "Transactions"
    },
    {
        path: "/superadmin-dashboard",
        icon: <Icon component={RiDashboardFill} style={{ fontSize: 20 }} />,
        label: "SuperAdmin Dashboard"
    },
    {
        path: "/invoices",
        icon: <Icon component={FaFileInvoiceDollar} style={{ fontSize: 20 }} />,
        label: "Invoices"
    },
    {
        path: "/promotions",
        icon: <Icon component={FaTicketAlt} style={{ fontSize: 20 }} />,
        label: "Promotions"
    },
    {
        path: "/pay-cycles",
        icon: <Icon component={FaRecycle} style={{ fontSize: 20 }} />,
        label: "Pay Cycles"
    },
    {
        path: "/pay-period",
        icon: <Icon component={FaWallet} style={{ fontSize: 20 }} />,
        label: "Start Pay Period"
    },
    {
        path: "/users",
        icon: <Icon component={FaUsers} style={{ fontSize: 20 }} />,
        label: "Users"
    },
    {
        path: "/deduction-summary",
        icon: <Icon component={FaFileInvoice} style={{ fontSize: 20 }} />,
        label: "Deduction Summary"
    },
    {
        path: "/payroll-data",
        icon: <Icon component={FaDatabase} style={{ fontSize: 20 }} />,
        label: "Payroll Data"
    }
]

export const supportMenuConfig = [
    {
        path: "/",
        icon: <Icon component={FaBuilding} style={{ fontSize: 20 }}/>,
        label: "Employers"
    },
    {
        path: "/employees",
        icon: <Icon component={FaUserTie} style={{ fontSize: 20 }}/>,
        label: "Employees"
    },
    {
        path: "/transactions",
        icon: <Icon component={BiTransfer} style={{ fontSize: 20 }} />,
        label: "Transactions"
    },
    {
        path: "/promotions",
        icon: <Icon component={FaTicketAlt} style={{ fontSize: 20 }} />,
        label: "Promotions"
    },
    {
        path: "/pay-cycles",
        icon: <Icon component={FaRecycle} style={{ fontSize: 20 }} />,
        label: "Pay Cycles"
    }
]


export const roleBasedConfigMap = {
    EMPLOYER: employerAdminMenuConfig,
    SUPPORT: supportMenuConfig,
    appAdmin: superAdminMenuConfig
}