import React, { useEffect, useState, useRef } from "react";
import ReactDOM from "react-dom";
import { Importer, ImporterField } from "react-csv-importer";
import "react-csv-importer/dist/index.css";
import "./index.css";

const CsvFileUpload = ({ expectedColumns }) => {
  const csvImporter = useRef(null);
  let [content, setContent] = useState(() => {
    return [];
  });
  let [errors, setErrors] = useState( [] );

  useEffect(() => {
  //  console.log(csvImporter.current.textContent);
  }, [])

  async function processChunk(rows, { startIndex }) {
    // Add this chunk to the end.

    setContent((prev) => {
      return [...prev, ...rows];
    });

    rows.forEach(row => {
      for ( let columnName in row){
        // let re = new RegExp( expectedColumns.filter( x => x.name === columnName)[0].validation);
        // let m = row[columnName].match(re);

        // console.log( "matching",{ row: row[columnName], re: re, m: m });
        let validation = expectedColumns.filter((x) => x.name === columnName)[0].validation;
        if( !row[columnName].match(validation.regex)){
          let error = { row: startIndex, columnName: columnName, columnValue: row[columnName], message: validation.errorMessage};
          setErrors((prev) => {
            return [...prev, error]
          })
        }
      }
      startIndex++;
    });
  }

  function uploadComplete({ file, preview, fields, columnFields }) {
    console.log("uploadcomplete", {content: content, errors: errors});
    // save to S3
  }

  function start({ file, fields, columns, skipHeaders }) {
    console.log("CsvFileUpload.start", { cols: expectedColumns });
    setContent([]);
  }
  return (
    <div>
      <p>
        <b>
          Please map your columns (top row) to our data attributes (bottom row)
          using drag and drop.
        </b>&nbsp;
        Some may have already been mapped by our system. Please review and
        double-check that all is correct.
      </p>
      <Importer
        chunkSize={10000} // optional, internal parsing chunk size in bytes
        assumeNoHeaders={false} // optional, keeps "data has headers" checkbox off by default
        restartable={true} // optional, lets user choose to upload another file when import is complete
        onStart={start}
        processChunk={processChunk}
        onComplete={uploadComplete}

        // CSV options passed directly to PapaParse if specified:
        // delimiter={...}
        // newline={...}
        // quoteChar={...}
        // escapeChar={...}
        // comments={...}
        // skipEmptyLines={...}
        // delimitersToGuess={...}
      >
        {expectedColumns.map((item) => (
          <ImporterField
            key={item.name}
            name={item.name}
            label={item.columnName}
          />
        ))}
      </Importer>
      {errors.length > 0 &&
      <div className="uploadErrors">
        {errors.map((item) => (
          <div>
              Row {item.row}: {item.columnName} [{item.columnValue}] - {item.message}
          </div>
        ))}
        Please correct the csv data and upload again.
      </div>
      }
    </div>
  );
};

export default CsvFileUpload;
