import EmployerDashboard from '../screens/employerDashboard/loadable'
import Employers from '../screens/employers/loadable'
import EmployerDetails from '../screens/employerDetails/loadable'
import EmployeeDetails from '../screens/employeeDetails/loadable'
import InvoiceDetails from '../screens/invoiceDetails/loadable'
import Employees from '../screens/employees/loadable'
import Transactions from '../screens/transactions/loadable'
import Login from '../screens/login/loadable'
import SuperAdminDashboard from '../screens/superAdminDashboard/loadable'
import Invoices from '../screens/invoices/loadable'
import Promotions from '../screens/promotions/loadable'
import PromotionDetails from '../screens/promotionDetails/loadable'
import PayCycles from '../screens/payCycles/loadable'
import StartPayPeriod from '../screens/startPayPeriod/loadable'
import Users from '../screens/users/loadable'
import ChangePassword from '../screens/changePassword/loadable'
import DeductionSummary from '../screens/deductionSummary/loadable'
import EmployerView from '../screens/employerView/loadable'
import PayrollData from '../screens/payrollData/loadable'
import EmployeeEligibility from '../screens/employeeEligibility/loadable'
import ForgotPassword from '../screens/forgotPassword/loadable'


export const employerAdminRoutes = [
    {
        path: "/",
        component: EmployerDashboard
    },
    {
        path: "/employer-view",
        component: EmployerView
    },
    {
        path: "/employees",
        component: Employees
    },
    {
        path: "/employee-details/:employeeId",
        component: EmployeeDetails
    },
    {
        path: "/transactions",
        component: Transactions
    },
    {
        path: "/pay-cycles",
        component: PayCycles
    },
    {
        path: "/pay-period",
        component: StartPayPeriod
    },
    {
        path: "/users",
        component: Users
    },
    {
        path: "/deduction-summary",
        component: DeductionSummary
    },
    {
        path: "/payroll-data",
        component: PayrollData
    },
    {
        path: "/employee-eligiblity",
        component: EmployeeEligibility
    },
    {
        path: "/change-password",
        component: ChangePassword,
    },
]

export const superAdminRoutes = [
    {
        path: "/",
        component: Employers
    },
    {
        path: "/employer-dashboard",
        component: EmployerDashboard
    },
    {
        path: "/employers",
        component: Employers
    },
    {
        path: "/employer-details/:id",
        component: EmployerDetails
    },
    {
        path: "/employees",
        component: Employees
    },
    {
        path: "/employee-details/:employeeId",
        component: EmployeeDetails
    },
    {
        path: "/transactions",
        component: Transactions
    },
    {
        path: "/superadmin-dashboard",
        component: SuperAdminDashboard
    },
    {
        path: "/invoices",
        component: Invoices
    },
    {
        path: "/invoice-details/:id",
        component: InvoiceDetails
    },
    {
        path: "/promotions",
        component: Promotions
    },
    {
        path: "/promotion-details/:id",
        component: PromotionDetails
    },
    {
        path: "/pay-cycles",
        component: PayCycles
    },
    {
        path: "/pay-period",
        component: StartPayPeriod
    },
    {
        path: "/users",
        component: Users
    },
    {
        path: "/deduction-summary",
        component: DeductionSummary
    },
    {
        path: "/payroll-data",
        component: PayrollData
    },
    {
        path: "/employee-eligiblity",
        component: EmployeeEligibility
    },
    {
        path: "/change-password",
        component: ChangePassword,
    },
]

export const supportRoutes = [
    {
        path: "/",
        component: Employers
    },
    {
        path: "/employers",
        component: Employers
    },
    {
        path: "/employer-details/:id",
        component: EmployerDetails
    },
    {
        path: "/employees",
        component: Employees
    },
    {
        path: "/employee-details/:employeeId",
        component: EmployeeDetails
    },
    {
        path: "/transactions",
        component: Transactions
    },
    {
        path: "/promotions",
        component: Promotions
    },
    {
        path: "/promotion-details/:id",
        component: PromotionDetails
    },
    {
        path: "/pay-cycles",
        component: PayCycles
    },
    {
        path: "/change-password",
        component: ChangePassword,
    },
]

export const authenticationRoutes = [
    {
        path: "/",
        name: "login",
        label: 'Login',
        icon: '',
        component: Login,
        route: "public"
    },
    {
        path: "/login",
        name: "login",
        label: 'Login',
        icon: '',
        component: Login,
        route: "public"
    },
    {
        path: "/invite",
        name: "login",
        label: 'Login',
        icon: '',
        component: Login,
        route: "public"
    },
    {
        path: "/change-password",
        name: "changePassword",
        label: 'changePassword',
        icon: '',
        component: ChangePassword,
        route: "public"
    },
    {
        path: "/forgot-password",
        name: "forgotPassword",
        label: 'forgotPassword',
        icon: '',
        component: ForgotPassword,
        route: "public"
    },
    {
        path: "*",
        name: "login",
        label: 'Login',
        icon: '',
        component: Login,
        route: "public"
    }
]

export const roleBasedRoutingMap = {
    EMPLOYER: employerAdminRoutes,
    SUPPORT: supportRoutes,
    appAdmin: superAdminRoutes
}