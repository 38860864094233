  
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_CREDENTIALS,
    GET_CREDENTIALS_LOADING,
    GET_CREDENTIALS_SUCCESS,
    GET_CREDENTIALS_ERROR,

    GET_FILE_PROCESS_STATUS,
    GET_FILE_PROCESS_STATUS_LOADING,
    GET_FILE_PROCESS_STATUS_SUCCESS,
    GET_FILE_PROCESS_STATUS_ERROR,

    UPLOAD_FILE_TO_S3,
    UPLOAD_FILE_TO_S3_LOADING,
    UPLOAD_FILE_TO_S3_SUCCESS,
    UPLOAD_FILE_TO_S3_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';

const baseURL = getBaseURL()

function* getCredentials({ body }) {
    yield put({ type: GET_CREDENTIALS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'auth/getCredentials',
            body
        });
        yield put({ type: GET_CREDENTIALS_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_CREDENTIALS_ERROR, errorMessage: '' });
    }
}

function* getPayrollDataHistory({ body }) {
    yield put({ type: GET_FILE_PROCESS_STATUS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'file-process-status',
            body
        });
        yield put({ type: GET_FILE_PROCESS_STATUS_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_FILE_PROCESS_STATUS_ERROR, errorMessage: '' });
    }
}

function* getTransactionTypes({ body }) {
    yield put({ type: UPLOAD_FILE_TO_S3_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/transactionType',
            body
        });
        yield put({ type: UPLOAD_FILE_TO_S3_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPLOAD_FILE_TO_S3_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchPayrollDataSaga() {
    yield takeLatest(GET_CREDENTIALS, getCredentials)
    yield takeLatest(GET_FILE_PROCESS_STATUS, getPayrollDataHistory)
    yield takeLatest(UPLOAD_FILE_TO_S3, getTransactionTypes)
}