import { call, put, takeLatest } from 'redux-saga/effects';
import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_LOADING,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';

const baseURL = getBaseURL()

function* forgotPassword({ body }) {
    yield put({ type: FORGOT_PASSWORD_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'auth/forgot',
            body
        });
        yield put({ type: FORGOT_PASSWORD_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: FORGOT_PASSWORD_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchForgotPasswordSaga() {
    yield takeLatest(FORGOT_PASSWORD, forgotPassword)
}