export const GET_PAY_PERIODS = "GET_PAY_PERIODS"
export const GET_PAY_PERIODS_LOADING = "GET_PAY_PERIODS_LOADING"
export const GET_PAY_PERIODS_SUCCESS = "GET_PAY_PERIODS_SUCCESS"
export const GET_PAY_PERIODS_ERROR = "GET_PAY_PERIODS_ERROR"

export const GET_DEDUCTION_SUMMARY = "GET_DEDUCTION_SUMMARY"
export const GET_DEDUCTION_SUMMARY_LOADING = "GET_DEDUCTION_SUMMARY_LOADING"
export const GET_DEDUCTION_SUMMARY_SUCCESS = "GET_DEDUCTION_SUMMARY_SUCCESS"
export const GET_DEDUCTION_SUMMARY_ERROR = "GET_DEDUCTION_SUMMARY_ERROR"

export const GET_DEDUCTION_SUMMARY_FILE = "GET_DEDUCTION_SUMMARY_FILE"
export const GET_DEDUCTION_SUMMARY_FILE_LOADING = "GET_DEDUCTION_SUMMARY_FILE_LOADING"
export const GET_DEDUCTION_SUMMARY_FILE_SUCCESS = "GET_DEDUCTION_SUMMARY_FILE_SUCCESS"
export const GET_DEDUCTION_SUMMARY_FILE_ERROR = "GET_DEDUCTION_SUMMARY_FILE_ERROR"

export const GET_FILE_BASED_DEDUCTION_REPORT = "GET_FILE_BASED_DEDUCTION_REPORT"
export const GET_FILE_BASED_DEDUCTION_REPORT_LOADING = "GET_FILE_BASED_DEDUCTION_REPORT_LOADING"
export const GET_FILE_BASED_DEDUCTION_REPORT_SUCCESS = "GET_FILE_BASED_DEDUCTION_REPORT_SUCCESS"
export const GET_FILE_BASED_DEDUCTION_REPORT_ERROR = "GET_FILE_BASED_DEDUCTION_REPORT_ERROR"


export const getPayPeriods = (body) => ({ type: GET_PAY_PERIODS, body })
export const resetDeductionSummary = () => ({ type: GET_DEDUCTION_SUMMARY_SUCCESS, data: {} })
export const getDeductionSummary = (body) => ({ type: GET_DEDUCTION_SUMMARY, body })
export const getDeductionSummaryFile = (body) => ({ type: GET_DEDUCTION_SUMMARY_FILE, body })
export const getFileBasedDeductionReport = (body) => ({ type: GET_FILE_BASED_DEDUCTION_REPORT, body })
