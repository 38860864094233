
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_EMPLOYEES,
    GET_EMPLOYEES_LOADING,
    GET_EMPLOYEES_SUCCESS,
    GET_EMPLOYEES_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';

const baseURL = getBaseURL()

function* getEmployees({ body }) {
    yield put({ type: GET_EMPLOYEES_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/employees',
            body
        });
        yield put({ type: GET_EMPLOYEES_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYEES_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployeesSaga() {
    yield takeLatest(GET_EMPLOYEES, getEmployees)
}