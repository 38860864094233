import axios from 'axios'
import { isString } from 'lodash'
import { getBaseURL, getS3BucketURL } from './helper'
import { message } from 'antd';
import { logout } from '../screens/login/actions';
import { getState, dispatch } from '../store/store';

const baseURL = getBaseURL()
const s3BucketPath = getS3BucketURL()

// Add a request interceptor
axios.interceptors.request.use(
    config => {
        if (config.url.indexOf(s3BucketPath) === -1) {
            const authState = getState('auth');
            const { tokens } = authState;
            const token = tokens.data && tokens.data.IdToken;
            if (token) {
                config.headers['Authorization'] = 'Bearer ' + token;
            }
        }
        return config;
    },
    error => {
        Promise.reject(error)
    });

//Add a response interceptor
axios.interceptors.response.use((response) => {
    return response
}, function (error) {
    if (error.response && error.response.status === 401) {
        message.error('Session expired!');
        window.Intercom('shutdown')
        dispatch(logout());
        return Promise.reject(error);
    }
    return Promise.reject(error);
});

export const api = ({ method, url, body, headers }) => {
    const regex = /{[^\/]*}/g;
    var m;
    if (body && isString(body))
        body = JSON.parse(JSON.stringify(body));
    while ((m = url.match(regex)) !== null) {
        var urlParamName = m[0].substring(1, m[0].length - 1);
        url = url.replace(m[0], body[urlParamName]);
        delete body[urlParamName];
    }
    if (headers == null)
        headers = {};
    if (method === 'GET' && body != null) {
        let keys = [];
        Object.keys(body).filter(item => body[item]).forEach(item => {
            if (Array.isArray(body[item])) {
                body[item].forEach(element => keys.push(item + "=" + encodeURIComponent(element)))
            } else if (body[item] instanceof Date) {
                keys.push(item + "=" + encodeURIComponent(body[item].toISOString()));
            } else {
                keys.push(item + "=" + encodeURIComponent(body[item]));
            }
        })
        if (Object.keys(body).length !== 0) {
            url += ("?" + keys.join("&"));
        }
    } else {
        body = body;
    }
    return axios({
        method: method,
        baseURL: baseURL,
        url: url,
        headers: headers,
        data: body,
    })
}