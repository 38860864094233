import { call, put, takeLatest } from "redux-saga/effects";
import {
  GET_TRANSACTIONS,
  GET_TRANSACTIONS_LOADING,
  GET_TRANSACTIONS_SUCCESS,
  GET_TRANSACTIONS_ERROR,
  GET_TRANSACTION_TYPES,
  GET_TRANSACTION_TYPES_LOADING,
  GET_TRANSACTION_TYPES_SUCCESS,
  GET_TRANSACTION_TYPES_ERROR,
  GET_EXPORT_TRANSACTIONS_ERROR,
  GET_EXPORT_TRANSACTIONS_SUCCESS,
  GET_EXPORT_TRANSACTIONS_LOADING,
  GET_EXPORT_TRANSACTIONS,
} from "./actions";
import { api } from "../../utils/api";
import { errorhandling, getBaseURL } from "../../utils/helper";

const baseURL = getBaseURL();

function* getTransactions({ body }) {
  yield put({ type: GET_TRANSACTIONS_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: baseURL + "salaryadvance/salary-manager/transactions",
      body,
    });
    yield put({ type: GET_TRANSACTIONS_SUCCESS, data: data.response });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_TRANSACTIONS_ERROR, errorMessage: "" });
  }
}

function* getExportTransactions({ body }) {
  yield put({ type: GET_EXPORT_TRANSACTIONS_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: baseURL + "salaryadvance/salary-manager/transactions/export",
      body,
    });
    yield put({
      type: GET_EXPORT_TRANSACTIONS_SUCCESS,
      data: data.response,
    });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_EXPORT_TRANSACTIONS_ERROR, errorMessage: "" });
  }
}

function* getTransactionTypes({ body }) {
  yield put({ type: GET_TRANSACTION_TYPES_LOADING });
  try {
    const { data } = yield call(api, {
      method: "GET",
      url: baseURL + "salaryadvance/salary-manager/transactionType",
      body,
    });
    yield put({ type: GET_TRANSACTION_TYPES_SUCCESS, data: data });
  } catch (error) {
    errorhandling(error);
    yield put({ type: GET_TRANSACTION_TYPES_ERROR, errorMessage: "" });
  }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchTransactionsSaga() {
  yield takeLatest(GET_TRANSACTIONS, getTransactions);
  yield takeLatest(GET_EXPORT_TRANSACTIONS, getExportTransactions);
  yield takeLatest(GET_TRANSACTION_TYPES, getTransactionTypes);
}
