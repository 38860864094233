import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { PrivateComponent } from "../components";
import {
  toggleSidebar,
  setSelectedEmployer,
  getEmployersList,
} from "../../../shared/actions";
import { isEqual } from "lodash";
import { logout } from "../../../screens/login/actions";
import { message } from "antd";

class Private extends React.Component {
  componentDidMount = () => {
    if (this.props.userDetails["custom:UserType"] !== "EMPLOYER") {
      this.props.getEmployersList();
    }

    this.intervalId = setInterval(() => {
      if (
        this.props.userDetails.exp &&
        this.props.userDetails.exp <= new Date().getTime() / 1000
      ) {
        message.error("Session expired!");
        window.Intercom("shutdown");
        this.props.logout();
      }
    }, 5000);

    if (
      this.props.userDetails["custom:UserType"] === "EMPLOYER" ||
      this.props.userDetails["custom:UserType"] === "appAdmin"
    ) {
      window.Intercom("shutdown");
      window.Intercom("boot", {
        app_id: "sqg8natf",
        name: `${this.props.userDetails["custom:FirstName"]} ${this.props.userDetails["custom:LastName"]}`,
        email: this.props.userDetails.email,
        created_at: this.props.userDetails["custom:LastLogin"],
      });
    }
  };

  componentDidUpdate = (prevProps) => {
    const { pathname } = this.props;
    if (prevProps.pathname !== pathname) {
      if (this.props.userDetails["custom:UserType"] === "EMPLOYER") {
        window.Intercom("update");
      }
    }
  };

  componentWillUnmount() {
    clearInterval(this.intervalId);
  }

  navigateTo = (route, params) => {
    const { history } = this.props;
    history.push(route, params);
  };

  handleMenuClick = (event) => {
    if (event.key === "change-password") {
      this.navigateTo("/change-password");
    }
  };

  handleLogOut = (e) => {
    const { tokens } = this.props;
    this.props.logout({ accessToken: tokens.data.AccessToken });
    this.navigateTo("/");
    window.Intercom("shutdown");
    window.Intercom("boot", {
      app_id: "sqg8natf",
    });
  };

  render() {
    const { selectedEmployer, employersList, isSidebarCollapsed, pathname } =
      this.props;
    return (
      <PrivateComponent
        path={pathname}
        userDetails={this.props.userDetails}
        isSidebarCollapsed={isSidebarCollapsed}
        employers={employersList.data.employers || []}
        toggleSidebar={this.props.toggleSidebar}
        selectedEmployer={selectedEmployer}
        setSelectedEmployer={this.props.setSelectedEmployer}
        navigateTo={this.navigateTo}
        handleMenuClick={this.handleMenuClick}
        handleLogOut={this.handleLogOut}
      />
    );
  }
}

const mapStateToProps = ({
  router: {
    location: { pathname },
  },
  shared: { employersList, isSidebarCollapsed, selectedEmployer },
  auth: { tokens, userDetails },
}) => ({
  pathname,
  employersList,
  userDetails,
  selectedEmployer,
  isSidebarCollapsed,
  tokens,
});

const mapDispatchToProps = {
  toggleSidebar,
  setSelectedEmployer,
  getEmployersList,
  logout,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Private)
);
