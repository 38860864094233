  
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_EMPLOYEE_BY_ID,
    GET_EMPLOYEE_BY_ID_LOADING,
    GET_EMPLOYEE_BY_ID_SUCCESS,
    GET_EMPLOYEE_BY_ID_ERROR,

    GET_EMPLOYEE_PAYTIME_INFORMATION,
    GET_EMPLOYEE_PAYTIME_INFORMATION_LOADING,
    GET_EMPLOYEE_PAYTIME_INFORMATION_SUCCESS,
    GET_EMPLOYEE_PAYTIME_INFORMATION_ERROR,

    UPDATE_EMPLOYEE_STATUS,
    UPDATE_EMPLOYEE_STATUS_LOADING,
    UPDATE_EMPLOYEE_STATUS_SUCCESS,
    UPDATE_EMPLOYEE_STATUS_ERROR
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';

const baseURL = getBaseURL()

function* getEmployeeById({ body }) {
    yield put({ type: GET_EMPLOYEE_BY_ID_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/employee',
            body
        });
        yield put({ type: GET_EMPLOYEE_BY_ID_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYEE_BY_ID_ERROR, errorMessage: '' });
    }
}

function* getEmployeePaytimeInformation({ body }) {
    yield put({ type: GET_EMPLOYEE_PAYTIME_INFORMATION_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/employee/paytime-info/{employeeId}',
            body
        });
        yield put({ type: GET_EMPLOYEE_PAYTIME_INFORMATION_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYEE_PAYTIME_INFORMATION_ERROR, errorMessage: '' });
    }
}

function* updateEmployeeStatus({ body }) {
    yield put({ type: UPDATE_EMPLOYEE_STATUS_LOADING });
    try {
        yield call(api, {
            method: 'POST',
            url: baseURL + 'salaryadvance/salary-manager/employee/status',
            body
        });
        yield put({ type: UPDATE_EMPLOYEE_STATUS_SUCCESS, data: { isDisabled: true } });
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPDATE_EMPLOYEE_STATUS_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployeeDetailsSaga() {
    yield takeLatest(GET_EMPLOYEE_BY_ID, getEmployeeById)
    yield takeLatest(GET_EMPLOYEE_PAYTIME_INFORMATION, getEmployeePaytimeInformation)
    yield takeLatest(UPDATE_EMPLOYEE_STATUS, updateEmployeeStatus)
}