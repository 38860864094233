
import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
    ADD_EMPLOYER,
    ADD_EMPLOYER_LOADING,
    ADD_EMPLOYER_SUCCESS,
    ADD_EMPLOYER_ERROR,

    ADD_EMPLOYER_V2,
    ADD_EMPLOYER_V2_LOADING,
    ADD_EMPLOYER_V2_SUCCESS,
    ADD_EMPLOYER_V2_ERROR,

    UPDATE_EMPLOYER,
    UPDATE_EMPLOYER_LOADING,
    UPDATE_EMPLOYER_SUCCESS,
    UPDATE_EMPLOYER_ERROR,

    GET_EMPLOYER_BY_ID,
    GET_EMPLOYER_BY_ID_LOADING,
    GET_EMPLOYER_BY_ID_SUCCESS,
    GET_EMPLOYER_BY_ID_ERROR,

    UPDATE_EMPLOYER_STATUS,
    UPDATE_EMPLOYER_STATUS_ERROR,
    UPDATE_EMPLOYER_STATUS_LOADING,
    UPDATE_EMPLOYER_STATUS_SUCCESS,

    GET_PRESIGNED_URL,
    GET_PRESIGNED_URL_ERROR,
    GET_PRESIGNED_URL_LOADING,
    GET_PRESIGNED_URL_SUCCESS,

    UPLOAD_FILE,
    UPLOAD_FILE_ERROR,
    UPLOAD_FILE_LOADING,
    UPLOAD_FILE_SUCCESS,

    READ_UPLOADED_FILE,
    READ_UPLOADED_FILE_ERROR,
    READ_UPLOADED_FILE_LOADING,
    READ_UPLOADED_LEGAL_FILE_SUCCESS,
    READ_UPLOADED_PAYROLL_FILE_SUCCESS,
    READ_UPLOADED_SAAS_FILE_SUCCESS,

    SET_CURRENT_STEP
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';

const baseURL = getBaseURL()

function* addEmployer({ body }) {
    yield put({ type: ADD_EMPLOYER_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'salaryadvance/salary-manager/employers',
            body
        });
        yield put({ type: ADD_EMPLOYER_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: ADD_EMPLOYER_ERROR, errorMessage: '' });
    }
}

function* addEmployerV2({ body }) {
    yield put({ type: ADD_EMPLOYER_V2_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'salaryadvance/salary-manager/v2/employers',
            body
        });
        yield put({ type: ADD_EMPLOYER_V2_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: ADD_EMPLOYER_V2_ERROR, errorMessage: '' });
    }
}

function* updateEmployer({ body }) {
    yield put({ type: UPDATE_EMPLOYER_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'PUT',
            url: baseURL + 'salaryadvance/salary-manager/employer/{employerId}',
            body
        });
        yield put({ type: UPDATE_EMPLOYER_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPDATE_EMPLOYER_ERROR, errorMessage: '' });
    }
}

function* getEmployerById({ body }) {
    yield put({ type: GET_EMPLOYER_BY_ID_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/salary-manager/employer/{employerId}',
            body
        });
        yield put({ type: GET_EMPLOYER_BY_ID_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_EMPLOYER_BY_ID_ERROR, errorMessage: '' });
    }
}

function* updateEmployeeStatus({ body }) {
    yield put({ type: UPDATE_EMPLOYER_STATUS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'salaryadvance/salary-manager/employer/status',
            body
        });
        yield put({ type: GET_EMPLOYER_BY_ID, body: { employerId: body.employerId } })
        yield put({ type: UPDATE_EMPLOYER_STATUS_SUCCESS, data: data });
        yield put({ type: SET_CURRENT_STEP, data: -1 })
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPDATE_EMPLOYER_STATUS_ERROR, errorMessage: '' });
    }
}

function* getPreSignedUrl({ body }) {
    yield put({ type: GET_PRESIGNED_URL_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'uploads/getsignedurl',
            body
        });
        yield put({ type: GET_PRESIGNED_URL_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_PRESIGNED_URL_ERROR, errorMessage: '' });
    }
}

function* uploadFile({ body }) {
    yield put({ type: UPLOAD_FILE_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'PUT',
            url: body.url,
            body: body.file.originFileObj
        });
        yield put({ type: UPLOAD_FILE_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: UPLOAD_FILE_ERROR, errorMessage: '' });
    }
}

function* readUploadedFile({ body }) {
    const { docType } = body
    delete body.docType
    yield put({ type: READ_UPLOADED_FILE_LOADING, data: { docType } });
    try {
        const { data } = yield call(api, {
            method: 'POST',
            url: baseURL + 'uploads/getitem',
            body: body
        });
        if(docType === "legalDocs"){
            yield put({ type: READ_UPLOADED_LEGAL_FILE_SUCCESS, data });
        } else if(docType === "payrollBankDDAuthDocs"){
            yield put({ type: READ_UPLOADED_PAYROLL_FILE_SUCCESS, data });
        } else {
            yield put({ type: READ_UPLOADED_SAAS_FILE_SUCCESS, data });
        }
    } catch (error) {
        errorhandling(error)
        yield put({ type: READ_UPLOADED_FILE_ERROR, data: { docType } });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchEmployerDetailsSaga() {
    yield takeLatest(ADD_EMPLOYER, addEmployer)
    yield takeLatest(ADD_EMPLOYER_V2, addEmployerV2)
    yield takeLatest(UPDATE_EMPLOYER, updateEmployer)
    yield takeLatest(GET_EMPLOYER_BY_ID, getEmployerById)
    yield takeLatest(UPDATE_EMPLOYER_STATUS, updateEmployeeStatus)
    yield takeLatest(GET_PRESIGNED_URL, getPreSignedUrl)
    yield takeLatest(UPLOAD_FILE, uploadFile)
    yield takeEvery(READ_UPLOADED_FILE, readUploadedFile)
}