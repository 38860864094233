  
import { call, put, takeLatest } from 'redux-saga/effects';
import {
    GET_PROMOTIONS,
    GET_PROMOTIONS_LOADING,
    GET_PROMOTIONS_SUCCESS,
    GET_PROMOTIONS_ERROR,
} from './actions';
import { api } from '../../utils/api';
import { errorhandling, getBaseURL } from '../../utils/helper';

const baseURL = getBaseURL()

function* getPromotions({ body }) {
    yield put({ type: GET_PROMOTIONS_LOADING });
    try {
        const { data } = yield call(api, {
            method: 'GET',
            url: baseURL + 'salaryadvance/promotions/employerPromoList',
            body
        });
        yield put({ type: GET_PROMOTIONS_SUCCESS, data: data });
    } catch (error) {
        errorhandling(error)
        yield put({ type: GET_PROMOTIONS_ERROR, errorMessage: '' });
    }
}

/**
 * Root saga manages watcher lifecycle
 */
export default function* watchPromotionsSaga() {
    yield takeLatest(GET_PROMOTIONS, getPromotions)
}