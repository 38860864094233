import { combineReducers } from 'redux';
import {

    GET_USERS_LOADING,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,

    GET_USER_BY_ID_LOADING,
    GET_USER_BY_ID_SUCCESS,
    GET_USER_BY_ID_ERROR

} from './actions'

const initialStateList = {
    loading: false, list: [], error: ""
}

const initialStateObject = {
    loading: false, data: {}, error: ""
}

const users = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateList
    }
    switch (action.type) {
        case GET_USERS_LOADING: {
            return {
                loading: true,
                list: [],
                error: ""
            }
        }
        case GET_USERS_SUCCESS: {
            return {
                loading: false,
                list: action.data,
                error: ""
            }
        }
        case GET_USERS_ERROR: {
            return {
                loading: false,
                list: [],
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

const user = (state, action) => {
    if (typeof state === 'undefined') {
        return initialStateObject
    }
    switch (action.type) {
        case GET_USER_BY_ID_LOADING: {
            return {
                loading: true,
                data: {},
                error: ""
            }
        }
        case GET_USER_BY_ID_SUCCESS: {
            return {
                loading: false,
                data: action.data,
                error: ""
            }
        }
        case GET_USER_BY_ID_ERROR: {
            return {
                loading: false,
                data: {},
                error: action.errorMessage
            }
        }
        default:
            return state
    }
};

export default combineReducers({
    users,
    user
});