import React from 'react'
import { Steps } from 'antd';

const { Step } = Steps;

const CustomStepper = ({ currentStep, steps, handleChange }) => {
    return (
        <Steps progressDot current={currentStep} onChange={handleChange}>
            {steps.map((item, index) => <Step key={index} title={item.title} />)}
        </Steps>
    )
}

export default CustomStepper